<template>
  <div class="order_wrap">
    <h1>{{ this.$t("order.order_title") }}</h1>

    <div class="section">
      <div class="header">{{ this.$t("order.order_product") }}</div>
      <div class="body">
        <ul class="product">
          <li v-for="(value, index) in product_data" :key="index">
            <div
              class="image"
              :style="{
                backgroundImage: 'url(' + value.product_image_url + ')',
              }"
            ></div>
            <div class="desc">
              <div class="name">{{ value.product_name }}</div>
              <div class="option">{{ value.product_option }}</div>
              <div class="count">
                {{ value.product_count }} {{ this.$t("order.count") }}
              </div>
              <div class="price">
                {{
                  this.common.currency_format(
                    (value.sale_price + value.extra_price) *
                      value.product_count,
                    value.unit
                  )
                }}
              </div>
            </div>
          </li>
        </ul>
        <div class="total_price">
          {{ this.common.currency_format(this.total_price, this.unit) }}
        </div>
      </div>
    </div>

    <div class="section">
      <div class="header">{{ this.$t("order.shipping_address") }}</div>
      <div class="body">
        <div class="form">
          <label>{{ this.$t("order.recipient") }}</label>
          <input type="text" v-model="this.recipient_name" />
        </div>
        <div class="form">
          <label>{{ this.$t("order.tel") }}</label>
          <input type="text" v-model="this.recipient_tel" />
        </div>
        <div class="form">
          <label>{{ this.$t("order.address") }}</label>
          <div class="box">
            <p>
              {{
                this.common.get_country_name_by_code3(
                  this.storage.get_user_country()
                )
              }}
            </p>
            <input
              type="text"
              class="postalCode"
              :placeholder="$t('order.postal_code')"
              v-model="this.recipient_postalcode"
              :readonly="this.storage.get_user_country() == 'KOR'"
              @click="this.open_address_popup"
            />
            <input
              type="text"
              class="address"
              :placeholder="$t('order.address')"
              v-model="this.recipient_address"
              :readonly="this.storage.get_user_country() == 'KOR'"
              @click="this.open_address_popup"
            />
            <input
              type="text"
              class="address"
              :placeholder="$t('order.detail_address')"
              v-model="this.recipient_address_detail"
            />
          </div>
        </div>
        <div class="form">
          <label>{{ this.$t("order.delivery_msg") }}</label>
          <input type="text" v-model="this.delivery_message" />
        </div>
      </div>
    </div>

    <div class="section">
      <div class="header">{{ this.$t("order.discount") }}</div>
      <div class="body">
        <div class="form">
          <label>{{ this.$t("order.coupon") }}</label>
          <div class="coupon">
            <div class="item" v-if="this.selected_coupon_map_id > 0">
              {{ this.selected_coupon_info }}
              <div class="delete" @click="this.clear_selected_coupon"></div>
            </div>
            <div class="add" @click="this.open_coupon_popup"></div>
          </div>
        </div>
        <div class="form">
          <label>{{ this.$t("order.point") }}</label>
          <div class="box">
            <input type="number" v-model="this.using_point" /><span
              class="current_point"
              >{{ this.$t("order.own_point") }}:
              {{ this.common.number_format(this.user_point) }}</span
            >
          </div>
        </div>
      </div>
    </div>

    <div class="section">
      <div class="header">{{ this.$t("order.payment_information") }}</div>
      <div class="body">
        <div class="payment">
          <div
            class="item credit"
            :class="[selected_payment == 'card' ? 'selected' : '']"
            @click="selected_payment = 'card'"
            v-if="this.storage.get_user_country() == 'KOR'"
          >
            {{ this.$t("order.credit_card") }}
          </div>
          <div
            class="item transfer"
            :class="[selected_payment == 'trans' ? 'selected' : '']"
            @click="selected_payment = 'trans'"
            v-if="this.storage.get_user_country() == 'KOR'"
          >
            {{ this.$t("order.wire_transfer") }}
          </div>
          <div
            class="item paypal"
            :class="[selected_payment == 'paypal' ? 'selected' : '']"
            @click="selected_payment = 'paypal'"
            v-if="this.storage.get_user_country() != 'KOR'"
          >
            {{ this.$t("order.paypal") }}
          </div>
          <!-- <div
            class="item mobile"
            :class="[selected_payment == 'mobile' ? 'selected' : '']"
            @click="selected_payment = 'mobile'"
          >
            {{ this.$t("order.mobile_pay") }}
          </div> -->
        </div>
      </div>
    </div>

    <div class="price_summary">
      <div class="block subtotal">
        <div class="title">{{ this.$t("order.subtotal") }}:</div>
        <div class="price">
          {{ this.common.currency_format(this.total_price, this.unit) }}
        </div>
      </div>
      <div class="block ship">
        <div class="title">{{ this.$t("order.shipping") }}:</div>
        <div class="price">{{ this.$t("order.all_free") }}</div>
      </div>
      <div class="block discount">
        <div class="title">{{ this.$t("order.discount") }}:</div>
        <div class="price">
          {{ this.common.currency_format(this.discount_price, this.unit) }}
        </div>
      </div>
      <div class="block total">
        <div class="title">{{ this.$t("order.total") }}:</div>
        <div class="price">
          {{ this.common.currency_format(this.final_price, this.unit) }}
        </div>
      </div>
    </div>

    <button class="button red pay" @click="try_to_pay">
      {{ this.$t("order.pay") }}
    </button>
  </div>

  <Transition name="popup">
    <div
      class="overlay"
      v-if="this.show_coupon_popup"
      @click="this.close_coupon_popup"
    ></div>
  </Transition>
  <Transition name="popup">
    <div class="coupon_select_wrap popup_wrap" v-if="this.show_coupon_popup">
      <div class="header">
        {{ this.$t("order.available_coupon") }}
        <div class="close" @click="this.close_coupon_popup"></div>
      </div>
      <div class="body">
        <ul>
          <li class="empty" v-if="this.coupon_data.length <= 0">
            {{ this.$t("order.no_available_coupon") }}
          </li>
          <li
            v-for="(value, index) in this.coupon_data"
            :key="index"
            @click="click_coupon(value.coupon_id, value.coupon_map_id)"
            :class="{
              selected: value.coupon_map_id == this.temp_selected_coupon_map_id,
            }"
          >
            <div class="top">
              <div class="name">{{ value.coupon_name }}</div>
              <div class="discount">
                <div class="amount">
                  {{
                    value.coupon_type == "PRICE"
                      ? this.common.number_format(value.coupon_amount)
                      : this.common.number_format(
                          value.coupon_amount * 100.0,
                          1
                        )
                  }}
                </div>
                <div class="unit">
                  {{ value.coupon_type == "PRICE" ? value.unit : "%" }}
                </div>
                <div
                  class="limit"
                  v-if="
                    value.coupon_limit > 0 && value.coupon_type == 'PERCENT'
                  "
                >
                  {{ this.$t("order.max") }}
                  {{
                    this.common.currency_format(value.coupon_limit, value.unit)
                  }}
                </div>
              </div>
            </div>
            <div class="bottom">
              <div class="date">
                {{ this.$t("order.issue_date") }}:
                {{
                  this.common.date_short_format(
                    this.common.date_parse(value.issue_date),
                    this.storage.get_user_country()
                  )
                }}
              </div>
              <div class="date">
                {{ this.$t("order.expire_date") }}:
                {{
                  this.common.date_short_format(
                    this.common.date_parse(value.expire_date)
                  )
                }}
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="footer">
        <button class="button middle" @click="select_coupon">
          {{ this.$t("order.select") }}
        </button>
      </div>
    </div>
  </Transition>

  <!-- 주소 찾기 팝업 -->
  <Transition name="popup">
    <div
      class="overlay"
      v-if="this.show_address_popup"
      @click="this.close_address_popup"
    ></div>
  </Transition>
  <Transition name="popup" @enter="this.render_address_layer">
    <div class="address_find_wrap popup_wrap" v-if="this.show_address_popup">
      <div class="header">
        주소찾기
        <div class="close" @click="this.close_address_popup"></div>
      </div>
      <div
        class="body"
        ref="addres_layer"
        :on-load="this.render_address_layer"
      ></div>
    </div>
  </Transition>
  <!-- 주소 찾기 팝업 끝 -->

  <Spinner v-show="processing" />
</template>

<script>
export default {
  name: "Order",
  metaInfo() {
    return {
      title: this.$t("meta.title") + " :: " + this.$t("meta.order"),
    };
  },
  watch: {
    using_point: function () {
      if (this.using_point > this.user_point) {
        this.using_point = this.user_point;
      } else if (this.using_point < 0) {
        this.using_point = 0;
      }
    },
  },
  data: function () {
    return {
      process_queue: [],
      invoice_token: "",
      cart_ids: [],
      product_data: [],

      unit: "",
      total_price: 0,
      coupon_discount: 0, // 쿠폰 할인 금액

      recipient_name: "",
      recipient_tel: "",
      recipient_country: "",
      recipient_postalcode: "",
      recipient_address: "",
      recipient_address_detail: "",
      delivery_message: "",

      show_coupon_popup: false,
      coupon_data: [],
      temp_selected_coupon_id: 0,
      temp_selected_coupon_map_id: 0,
      selected_coupon_id: 0,
      selected_coupon_map_id: 0,
      selected_coupon_info: "",

      user_point: 0, // 사용자 보유 포인트
      using_point: 0, // 사용할 포인트

      selected_payment: "",

      show_address_popup: false,
    };
  },
  computed: {
    // 스피너 동작 여부
    processing() {
      if (this.process_queue.length <= 0) return false;
      else return true;
    },

    // 최종 가격
    final_price() {
      return this.total_price - this.using_point - this.coupon_discount;
    },

    // 할인 금액
    discount_price() {
      return this.using_point + this.coupon_discount;
    },

    // 주문 제품 명
    order_product_name() {
      if (this.product_data.length <= 0) {
        return "";
      } else if (this.product_data.length == 1) {
        return this.product_data[0].product_name;
      }

      return (
        this.product_data[0].product_name +
        " " +
        this.$t("order.order_product_and") +
        " " +
        (this.product_data.length - 1).toString() +
        " " +
        this.$t("order.order_product_count")
      );
    },
  },
  mounted() {
    this.init_data();
    this.init_user_data();
    this.init_coupon_data();
    this.init_product_data();
  },
  methods: {
    // 기본 데이터 초기화
    init_data: function () {
      let self = this;

      // 로그인 여부 확인
      if (!this.storage.is_logged_in()) {
        this.$swal.fire(self.$t("auth.error_no_login")).then((result) => {
          result;
          self.$router.back();
        });

        return;
      }

      // 인보이스 토큰
      this.invoice_token = this.$route.params.invoice_token;

      self.process_queue.push(1);

      // 인보이스 토큰 검증 (이미 주문이 된 것인지...)
      this.axios
        .post(
          process.env.VUE_APP_API_URL + "/order/token",
          {
            user_id: self.storage.get_user_id(),
            invoice_token: self.invoice_token,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          let data = response.data;

          // 이미 완료된 주문인 경우...
          if (data.code == 200 && data.result == "ORDERED") {
            self.$swal
              .fire({
                text: self.$t("order.error_already_ordered"),
                icon: "error",
              })
              .then((result) => {
                result;
                self.$router.push("/");
                return;
              });
          } else if (data.code > 200) {
            this.$swal
              .fire({ text: self.$t("common.error_occur"), icon: "error" })
              .then((result) => {
                result;
                self.$router.push("/");
              });
          }
        })
        .finally(function () {
          self.process_queue.pop();
        }); //-- axios
    }, //-- init_data

    // 사용자 포인트 정보 가져오기
    init_user_data: function () {
      if (!this.storage.is_logged_in()) return;

      let self = this;
      self.process_queue.push(1);

      this.axios
        .post(
          process.env.VUE_APP_API_URL + "/auth/get",
          {
            user_id: self.storage.get_user_id(),
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          let data = response.data.data;
          self.user_point = data.user_point;

          // 기본 주문자 정보 설정
          self.recipient_name = data.user_name;
          self.recipient_tel = data.user_mobile;
        })
        .finally(function () {
          self.process_queue.pop();
        });
    }, //-- init_user_data

    // 쿠폰 정보 가져오기
    init_coupon_data: function () {
      if (!this.storage.is_logged_in()) return;

      let self = this;
      self.process_queue.push(1);

      this.axios
        .post(
          process.env.VUE_APP_API_URL + "/coupon/list",
          {
            user_id: self.storage.get_user_id(),
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          let data = response.data;

          self.coupon_data = [];
          for (let i = 0; i < data.length; i++) {
            self.coupon_data.push(data[i]);
          }
        })
        .finally(function () {
          self.process_queue.pop();
        });
    }, //-- init_coupon_data

    // 인보이스에 저장되어 있는 제품 정보 가져오기
    init_product_data: function () {
      if (!this.storage.is_logged_in()) return;

      let self = this;
      self.process_queue.push(1);

      this.axios
        .post(
          process.env.VUE_APP_API_URL + "/invoice/list",
          {
            user_id: self.storage.get_user_id(),
            invoice_token: self.invoice_token,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          let data = response.data;

          self.product_data = [];
          self.total_price = 0;

          for (let i = 0; i < data.length; i++) {
            let item = {
              invoice_id: data[i].invoice_id,
              invoice_status: data[i].invoice_status,
              cart_id: data[i].cart_id,
              product_option: data[i].product_option,
              product_count: parseInt(data[i].product_count),
              combination_id: data[i].product_option_combination_id,
              extra_price:
                data[i].product_option_combination_id <= 0
                  ? 0
                  : parseFloat(data[i].extra_price),
              combination_status: data[i].combination_status,

              product_id: data[i].product_id,
              product_name: data[i].product_name,
              product_image: data[i].product_images.split("|")[0],
              retail_price: parseFloat(data[i].retail_price),
              sale_price: parseFloat(data[i].sale_price),
              seller_id: data[i].seller_id,
              seller_name: data[i].seller_name,

              unit: data[i].unit,

              selected: true,
            };

            item.product_image_url =
              process.env.VUE_APP_API_URL +
              "/resource/get/product/" +
              item.product_id +
              "/" +
              item.product_image;

            self.product_data.push(item);

            self.unit = item.unit;

            // 전체 가격 계산
            self.total_price +=
              (item.sale_price + item.extra_price) * item.product_count;
          }
        })
        .finally(function () {
          self.process_queue.pop();
        }); //-- axios
    }, //-- init_product_data

    // 쿠폰 선택 팝업 창 열기
    open_coupon_popup: function () {
      this.show_coupon_popup = true;
      this.temp_selected_coupon_id = 0;
      this.temp_selected_coupon_map_id = 0;
    },

    // 쿠폰 선택 팝업 창 닫기
    close_coupon_popup: function () {
      this.show_coupon_popup = false;
      this.temp_selected_coupon_id = 0;
      this.temp_selected_coupon_map_id = 0;
    },

    // 쿠폰 클릭 이벤트 핸들러
    click_coupon: function (coupon_id, coupon_map_id) {
      this.temp_selected_coupon_id = coupon_id;
      this.temp_selected_coupon_map_id = coupon_map_id;
    },

    // 쿠폰 선택하기
    select_coupon: function () {
      if (
        this.temp_selected_coupon_id <= 0 ||
        this.temp_selected_coupon_map_id <= 0
      ) {
        this.$swal.fire({
          text: this.$t("order.error_no_coupon_selected"),
          icon: "error",
        });
        return;
      }

      this.selected_coupon_id = this.temp_selected_coupon_id;
      this.selected_coupon_map_id = this.temp_selected_coupon_map_id;

      // 선택된 쿠폰 할인 가격 계산 및 표시 정보 만들기
      for (let i = 0; i < this.coupon_data.length; i++) {
        if (
          this.coupon_data[i].coupon_map_id == this.temp_selected_coupon_map_id
        ) {
          let coupon_name = this.coupon_data[i].coupon_name;
          let coupon_amount = this.coupon_data[i].coupon_amount;
          let coupon_type = this.coupon_data[i].coupon_type;
          let coupon_limit = this.coupon_data[i].coupon_limit;
          let unit = this.coupon_data[i].unit;

          let extra_info = ""; // 표시 정보
          let discount = 0; // 할인 금액
          if (coupon_type == "PERCENT") {
            discount = this.total_price * coupon_amount;
            if (coupon_limit > 0 && coupon_limit < discount) {
              discount = coupon_limit;
            }

            extra_info =
              this.common.number_format(coupon_amount * 100.0, 1) + "%";
          } else {
            discount = coupon_amount;
            extra_info = this.common.currency_format(coupon_amount, unit);
          }

          if (discount > this.total_price) {
            discount = this.total_price;
          }

          // 할인 금액 할당
          this.coupon_discount = discount;

          // 표시 정보 만들기
          this.selected_coupon_info = coupon_name + " (" + extra_info + ")";

          break;
        }
      }

      this.close_coupon_popup(); // 팝업 닫기
    },

    // 선택된 쿠폰 정보 초기화
    clear_selected_coupon: function () {
      this.selected_coupon_id = 0;
      this.selected_coupon_map_id = 0;
      this.selected_coupon_info = "";
      this.coupon_discount = 0;
    },

    // 결제 하기 (1단계)
    try_to_pay: function () {
      let self = this;
      if (this.product_data.length <= 0) {
        self.$swal.fire({
          text: self.$t("order.error_no_product"),
          icon: "error",
        });
        return;
      }

      if (this.recipient_name.trim() == "") {
        self.$swal.fire({
          text: self.$t("order.error_no_recipient_name"),
          icon: "error",
        });
        return;
      }

      if (this.recipient_tel.trim() == "") {
        self.$swal.fire({
          text: self.$t("order.error_no_recipient_tel"),
          icon: "error",
        });
        return;
      }

      if (this.recipient_postalcode.trim() == "") {
        self.$swal.fire({
          text: self.$t("order.error_no_postalcode"),
          icon: "error",
        });
        return;
      }

      if (this.recipient_address.trim() == "") {
        self.$swal.fire({
          text: self.$t("order.error_no_address"),
          icon: "error",
        });
        return;
      }

      if (this.final_price <= 0) {
        self.$swal.fire({
          text: self.$t("order.error_zero_price"),
          icon: "error",
        });
        return;
      }

      if (this.selected_payment == "") {
        self.$swal.fire({
          text: self.$t("order.error_no_payment_method"),
          icon: "error",
        });
        return;
      }

      self.$swal
        .fire({
          text: self.$t("order.confirm_pay"),
          icon: "question",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            self.request_to_pay();
          }
        });
    }, //-- try_to_pay

    // PG사에 결제 요청
    request_to_pay: function () {
      // 0보다 작은 금액은 결제 불가
      if (this.final_price <= 0) return;

      let self = this;

      const { IMP } = window;
      IMP.init("imp81222167");

      // 국가에 따라 PG사 선택
      let pg_provider = "uplus";
      let pay_method = self.selected_payment;

      if (this.storage.get_user_country() == "KOR") {
        pg_provider = "uplus";
        pay_method = self.selected_payment;
      } else {
        if (self.selected_payment == "paypal") {
          pg_provider = "paypal";
          pay_method = "card";
        }
      }

      const pay_data = {
        pg: pg_provider, // PG사
        pay_method: pay_method, // 결제수단
        merchant_uid: self.invoice_token, // 주문번호
        amount: self.final_price, // 결제금액
        currency: self.unit, // 통화
        name: self.order_product_name, // 주문명
        language: self.storage.get_user_country() == "KOR" ? "ko" : "en",
        buyer_name: self.storage.get_user_name(), // 구매자 이름
        buyer_tel: self.recipient_tel, // 구매자 전화번호
        buyer_email: self.storage.get_user_email(), // 구매자 이메일
        buyer_addr: self.recipient_address, // 구매자 주소
        buyer_postcode: self.recipient_postalcode, // 구매자 우편번호

        m_redirect_url: window.location.origin + "/order/mobile", // 모마일 리디렉션 URL
      };

      if (pay_data.pay_method == "trans") {
        pay_data.escrow = true;
      }

      // 추가 전달 결제 정보 데이터
      const payment_data = {
        invoice_token: self.invoice_token,
        user_id: self.storage.get_user_id(),

        recipient_name: self.recipient_name,
        recipient_tel: self.recipient_tel,
        recipient_postalcode: self.recipient_postalcode,
        recipient_address: self.recipient_address,
        recipient_address_detail: self.recipient_address_detail,
        delivery_message: self.delivery_message,

        payment_point: self.using_point,
        coupon_id: self.selected_coupon_id,
        coupon_map_id: self.selected_coupon_map_id,
        payment_method: self.selected_payment,
      };

      // 모마일 리디렉션 URL 쿼리 스트링
      let redirect_query = Object.entries(payment_data)
        .map((e) => e.join("="))
        .join("&");

      // 모마일 리디렉션 URL
      pay_data.m_redirect_url =
        window.location.origin + "/order/mobile?" + redirect_query;

      // 아임포트 결제 요청
      IMP.request_pay(pay_data, function (response) {
        self.callback_pay(response, payment_data);
      });
    }, //-- request_to_pay

    // PG 결제 성공 후 처리
    callback_pay: function (response, payment_data) {
      let self = this;

      const { success, imp_uid, merchant_uid, error_msg } = response;

      payment_data.imp_uid = imp_uid;
      payment_data.merchant_uid = merchant_uid;

      console.log(imp_uid);
      console.log(merchant_uid);
      console.log(error_msg);

      if (!success) {
        self.$swal.fire({
          text: self.$t("order.error_fail_to_pay"),
          icon: "error",
        });
        return;
      }

      self.process_queue.push(1);

      // 결제 검증
      this.axios
        .post(process.env.VUE_APP_API_URL + "/order/pay", payment_data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (response) {
          let data = response.data;

          if (data.result != "OK") {
            self.$swal.fire({
              text: self.$t("common.error_occur"),
              icon: "error",
            });
            return;
          }

          let order_id = 0;
          if (typeof data.data.order_id != "undefined") {
            order_id = data.data.order_id;
          }

          let route_arg = {
            path: "/order/complete",
            query: { order_id: order_id },
          };
          self.$router.push(route_arg);
        })
        .finally(function () {
          self.process_queue.pop();
        }); //-- axios
    }, //-- callback_pay

    close_address_popup: function () {
      this.show_address_popup = false;
    },

    open_address_popup: function () {
      if (this.storage.get_user_country() != "KOR") {
        return;
      }

      this.show_address_popup = true;
    }, //-- open_address_popup

    render_address_layer: function () {
      let layer = this.$refs.addres_layer;

      let self = this;
      new window.daum.Postcode({
        oncomplete: function (data) {
          let addr = "";
          let detail_addr = "";

          if (data.userSelectedType === "R") {
            // 사용자가 도로명 주소를 선택했을 경우
            addr = data.roadAddress;
          } else {
            // 사용자가 지번 주소를 선택했을 경우(J)
            addr = data.jibunAddress;
          }

          if (data.userSelectedType === "R") {
            // 법정동명이 있을 경우 추가한다. (법정리는 제외)
            // 법정동의 경우 마지막 문자가 "동/로/가"로 끝난다.
            if (data.bname !== "" && /[동|로|가]$/g.test(data.bname)) {
              detail_addr += data.bname;
            }
            // 건물명이 있고, 공동주택일 경우 추가한다.
            if (data.buildingName !== "" && data.apartment === "Y") {
              detail_addr +=
                detail_addr !== ""
                  ? ", " + data.buildingName
                  : data.buildingName;
            }
            // 표시할 참고항목이 있을 경우, 괄호까지 추가한 최종 문자열을 만든다.
            if (detail_addr !== "") {
              detail_addr = " (" + detail_addr + ")";
            }
            // 조합된 참고항목을 해당 필드에 넣는다.
            self.recipient_address_detail = detail_addr;
          } else {
            self.recipient_address_detail = "";
          }

          self.recipient_postalcode = data.zonecode;
          self.recipient_address = addr;

          self.show_address_popup = false;
        },
        width: "100%",
        height: "100%",
      }).embed(layer);
    }, //-- render_address_layer
  },
};
</script>

<style scoped>
.order_wrap {
  display: block;
  width: 1200px;
  max-width: 100%;
  margin: 0 auto;
  padding: 1rem;

  background: #fff;
}
.order_wrap > .header {
  position: relative;
  border-bottom: 1px solid #dedede;
  padding: 1rem 0;
}

.order_wrap > .section {
  margin: 2rem 0 4rem 0;
  border-top: 1px solid #dedede;
  border-bottom: 1px solid #dedede;
}
.order_wrap > .section > .header {
  padding: 0.8rem;
  font-size: 1.2rem;
  font-weight: 500;
  border-bottom: 1px solid #dedede;
  background: #efefef;
}
.order_wrap > .section > .body {
}
.order_wrap > .section > .body > ul.product {
  list-style-type: none;
  display: block !important;
}
.order_wrap > .section > .body > ul.product > li {
  width: 100%;
  padding: 0.8rem;
  margin: 0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  border-bottom: 1px solid #dedede;
}
.order_wrap > .section > .body > ul.product > li:last-child {
  border-bottom: none;
}
.order_wrap > .section > .body > ul.product > li > .image {
  width: 120px;
  height: 120px;
  background-position: center center;
  background-size: cover;
  margin: 0;
  padding: 0;
}
.order_wrap > .section > .body > ul.product > li > .desc {
  flex-grow: 1;
  padding: 0 0.8rem;
  font-size: 1rem;
}
.order_wrap > .section > .body > ul.product > li > .desc > .name {
  font-size: 1.2rem;
  font-weight: 700;
  margin: 0 0 0.3rem 0;
}
.order_wrap > .section > .body > ul.product > li > .desc > .option,
.order_wrap > .section > .body > ul.product > li > .desc > .count {
  display: inline-block;
  margin: 0 1rem 0.3rem 0;
  color: #808080;
}
.order_wrap > .section > .body > ul.product > li > .desc > .price {
  font-size: 1.1rem;
  font-weight: 400;
}
.order_wrap > .section > .body > .total_price {
  font-size: 1.1rem;
  font-weight: 700;
  text-align: right;
  padding: 0.8rem;
  border-top: 1px solid #dedede;
}
.order_wrap > .section > .body > .form {
  padding: 0.8rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.order_wrap > .section > .body > .form > label {
  display: block;
  width: 96px;
  font-weight: 500;
  padding: 0;
  margin: 0;
}

.order_wrap > .section > .body > .form > .box {
  position: relative;
  width: calc(100% - 96px);
  flex-grow: 1;
}
.order_wrap > .section > .body > .form input {
  display: block;
  width: 320px;
  max-width: calc(100% - 96px);
}
.order_wrap > .section > .body > .form > .box input {
  max-width: 100%;
}
.order_wrap > .section > .body > .form input.postalCode {
  margin-bottom: 0.4rem;
}
.order_wrap > .section > .body > .form input.address {
  width: 100%;
  margin-bottom: 0.4rem;
}
.order_wrap > .section > .body > .form input.address:last-child {
  margin-bottom: 0;
}

.order_wrap > .section > .body > .form .current_point {
  display: block;
  margin: 0.4rem 0 0 0;
}
.order_wrap > .section > .body > .form .coupon {
  padding: 0.4rem 0;
}
.order_wrap > .section > .body > .form .coupon > .item {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin-right: 1rem;
  padding: 0.4rem 2.2rem 0.4rem 0.8rem;

  font-size: 0.875rem;
  font-weight: 400;
  color: #fff;
  background: #e74356;
  border-radius: 0.8rem;
}
.order_wrap > .section > .body > .form .coupon > .item > .delete {
  position: absolute;
  right: 0.6rem;
  bottom: 50%;
  transform: translate(0, 50%);

  width: 1.2rem;
  height: 1.2rem;
  background: #000 url("../../assets/img/icon_close_white.svg") no-repeat center
    center;
  background-size: auto 75%;
  border-radius: 50%;
  cursor: pointer;
}
.order_wrap > .section > .body > .form .coupon > .add {
  display: inline-block;
  vertical-align: middle;

  width: 1.8rem;
  height: 1.8rem;

  background: #e74356 url("../../assets/img/icon_plus_white.svg") no-repeat
    center center;
  background-size: auto 60%;
  border-radius: 50%;

  cursor: pointer;
}

.order_wrap > .section > .body > .payment {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;

  padding: 1rem;
}
.order_wrap > .section > .body > .payment > .item {
  width: 140px;

  margin: 0 0.4rem 0.4rem 0;
  padding: 3.8rem 0.8rem 0.8rem 0.8rem;

  background-repeat: no-repeat;
  background-position: center 1rem;
  background-size: auto 1.8rem;

  border: 1px solid #dedede;
  border-radius: 0.4rem;

  text-align: center;
  font-size: 1.1rem;
  font-weight: 700;

  cursor: pointer;
}
.order_wrap > .section > .body > .payment > .item.credit {
  background-image: url("../../assets/img/icon_credit_card.svg");
}
.order_wrap > .section > .body > .payment > .item.transfer {
  background-image: url("../../assets/img/icon_bank.svg");
}
.order_wrap > .section > .body > .payment > .item.mobile {
  background-image: url("../../assets/img/icon_mobile.svg");
}
.order_wrap > .section > .body > .payment > .item.paypal {
  background-image: url("../../assets/img/icon_paypal.svg");
}
.order_wrap > .section > .body > .payment > .item.selected {
  border-color: #e74356;
}

.order_wrap > .price_summary {
  margin: 3rem 0 0 0;

  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-direction: column;
}
.order_wrap > .price_summary > .block {
  width: 320px;
  max-width: 100%;
  padding: 0.4rem;

  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.order_wrap > .price_summary > .block > .title {
  font-size: 1.2rem;
  font-weight: 700;

  width: 120px;
}
.order_wrap > .price_summary > .block > .price {
  flex-grow: 1;
  font-size: 1.3rem;
  font-weight: 400;

  text-align: right;
}
.order_wrap > .price_summary > .block.total > .title {
  font-size: 1.3rem;
}
.order_wrap > .price_summary > .block.total > .price {
  font-size: 1.4rem;
  font-weight: 700;
}

.order_wrap > button.pay {
  display: block;
  margin: 2rem auto;
}
.coupon_select_wrap > .body > ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.coupon_select_wrap > .body > ul > li {
  display: block;
  border: 1px solid #ccc;
  margin: 0 0 0.8rem 0;

  user-select: none;
  cursor: pointer;
}
.coupon_select_wrap > .body > ul > li.selected {
  border-color: #e74356;
}
.coupon_select_wrap > .body > ul > li.empty {
  border: none;
  text-align: center;
  padding: 3rem 0;
}
.coupon_select_wrap > .body > ul > li > .top,
.coupon_select_wrap > .body > ul > li > .bottom {
  position: relative;
  padding: 0.8rem;
}
.coupon_select_wrap > .body > ul > li > .top {
  background: url("../../assets/img/logo_600.png") no-repeat;
  background-position: center right 1rem;
  background-size: 2.4rem auto;
}
.coupon_select_wrap > .body > ul > li > .bottom {
  border-top: 1px solid #ccc;
  background: #f4f4f4;
}
.coupon_select_wrap > .body > ul > li > .top > .name {
  font-weight: 700;
  margin: 0 0 0.2rem 0;
}
.coupon_select_wrap > .body > ul > li > .top > .discount {
  display: block;
  color: #e74356;
  font-weight: 700;
  font-size: 1.4rem;
}
.coupon_select_wrap > .body > ul > li > .top > .discount > .amount {
  display: inline-block;
}
.coupon_select_wrap > .body > ul > li > .top > .discount > .unit {
  display: inline-block;
  font-size: 1rem;
  font-weight: 400;
}
.coupon_select_wrap > .body > ul > li > .top > .discount > .limit {
  display: inline-block;
  color: #808080;
  font-size: 0.875rem;
  font-weight: 400;
  margin: 0 0 0 0.8rem;
}
.coupon_select_wrap > .body > ul > li > .bottom > .date {
  font-size: 0.875rem;
  color: #808080;
  margin: 0 0 0.2rem 0;
}
.coupon_select_wrap > .body > ul > li > .bottom > .date:last-child {
  margin: 0;
}

.address_find_wrap > .body {
  position: relative;
  width: 100%;
  height: 540px;
  max-height: 60vh;
}
.address_find_wrap > .body iframe {
  display: block;
  width: 100%;
  height: 100%;
  border: none;
}

@media (max-width: 600px) {
  .order_wrap > .price_summary > .block {
    width: 100%;
  }
}
</style>
